<template>
  <div>
    <ads-title
      :class-list="['text-sm', 'font-semibold', 'py-2']"
      name="投放策略设置"
    >
      <el-dropdown @command="addBid">
        <span class="el-dropdown-link"> 添加策略<i class="el-icon-arrow-down el-icon--right"></i> </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="item in typeList"
            :command="item.value"
            >{{ item.name }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </ads-title>

    <div class="px-2 bidAmount">
      <el-form
        ref="form"
        :model="item"
        label-width="100px"
        size="small"
        class="py-4 bg-slate-100 rounded-lg mb-2 pr-6 relative"
        v-for="(item, index) in bidList"
      >
        <el-row
          :gutter="10"
          class="static"
        >
          <i
            class="el-icon-circle-close absolute right-0 top-0 cursor-pointer text-lg"
            @click="removeBid(index)"
          ></i>
          <el-col :span="5">
            <el-form-item
              label="智能广告："
              class="mb-0"
            >
              <el-select
                v-model="item.isAutoShoppingAd"
                placeholder=""
                @change="(v) => selectAdType(v, index)"
              >
                <el-option
                  v-for="item in adType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="预算方式："
              class="mb-0"
            >
              <el-select
                v-model="item.isCbo"
                placeholder=""
                :disabled="item.isAutoShoppingAd"
              >
                <el-option
                  v-for="item in budgetType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item
              label="预算金额："
              class="mb-0"
              :prop="item.budgetKey == 'lifetime' ? 'lifetimeBudget' : 'dailyBudget'"
              :rules="[
                { required: true, message: '预算金额不能为空', trigger: ['blur', 'change'] },
                { type: 'number', min: 0.01, message: '出价最小值为0.01' },
              ]"
            >
              <el-input
                v-model.number="item[item.budgetKey == 'lifetime' ? 'lifetimeBudget' : 'dailyBudget']"
                type="number"
                placeholder=""
                @change="emitMsg"
                :min="0"
              >
                <el-select
                  v-model="item.budgetKey"
                  slot="prepend"
                  placeholder="请选择"
                  style="width: 100px"
                  @change="(v) => selectBudgetType(v, index)"
                >
                  <el-option
                    label="日预算"
                    value="daily"
                  ></el-option>
                  <el-option
                    label="总预算"
                    value="lifetime"
                  ></el-option>
                </el-select>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item
              label="出价方式："
              class="mb-0"
            >
              <el-select
                v-model="item.bidStrategy"
                placeholder=""
                @change="(v) => selectBidType(v, index)"
              >
                <el-option
                  v-for="item in bidType(item.isAutoShoppingAd)"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item
              label="出价："
              class="mb-0"
              prop="bidAmount"
              v-if="item.bidStrategy != 'LOWEST_COST_WITHOUT_CAP'"
              :rules="[
                { required: true, message: '出价不能为空', trigger: ['blur', 'change'] },
                { type: 'number', min: 0.01, message: '出价最小值为0.01' },
              ]"
            >
              <el-input
                v-model.number="item.bidAmount"
                placeholder=""
                type="number"
                @change="emitMsg"
                :min="0"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          :gutter="10"
          class="mt-2"
        >
          <el-col :span="7">
            <el-form-item
              label="开始时间："
              class="mb-0"
              prop="startTime"
              :rules="[
                { required: true, message: '开始时间必填', trigger: ['blur', 'change'] },
                {
                  validator: (rule, value, callback) => validateStartTime(rule, value, callback),
                },
              ]"
            >
              <el-date-picker
                v-model="item.startTime"
                type="datetime"
                placeholder="选择日期时间"
                @change="(v) => setStartTime(v, index)"
                :picker-options="pickerOptions"
              >
              </el-date-picker> </el-form-item
          ></el-col>
          <el-col :span="10">
            <el-form-item
              label="结束时间："
              class="mb-0"
              prop="endTime"
              v-if="item.budgetKey == 'lifetime'"
              :rules="[
                { required: true, message: '结束时间必填', trigger: ['blur', 'change'] },
                {
                  validator: (rule, value, callback) => validateEndTime(item.startTime, rule, value, callback),
                },
              ]"
            >
              <el-date-picker
                v-model="item.endTime"
                type="datetime"
                placeholder="选择日期时间"
                @change="(v) => setEndTime(v, index)"
                :picker-options="pickerOptions"
              >
              </el-date-picker> </el-form-item
          ></el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
<script>
import adsTitle from './adsTitle.vue';
import moment from 'moment';
export default {
  props: {
    initData: {
      type: Object,
      default: () => {
        return {
          bidTypeMap: [],
        };
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    campaign: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      typeList: [
        {
          name: '系列预算自动竞价',
          value: {
            isAutoShoppingAd: false,
            isCbo: true,
            dailyBudget: null,
            lifetimeBudget: null,
            bidStrategy: 'LOWEST_COST_WITHOUT_CAP',
            bidAmount: null,
            budgetKey: 'daily',
            endTime: '',
          },
        },
        {
          name: '系列预算手动出价',
          value: {
            isAutoShoppingAd: false,
            isCbo: true,
            dailyBudget: null,
            lifetimeBudget: null,
            bidStrategy: 'LOWEST_COST_WITH_BID_CAP',
            bidAmount: null,
            budgetKey: 'daily',
            endTime: '',
          },
        },
        {
          name: '系列预算目标费用',
          value: {
            isAutoShoppingAd: false,
            isCbo: true,
            dailyBudget: null,
            lifetimeBudget: null,
            bidStrategy: 'COST_CAP',
            bidAmount: null,
            budgetKey: 'daily',
            endTime: '',
          },
        },
        {
          name: '组预算自动竞价',
          value: {
            isAutoShoppingAd: false,
            isCbo: false,
            dailyBudget: null,
            lifetimeBudget: null,
            bidStrategy: 'LOWEST_COST_WITHOUT_CAP',
            bidAmount: null,
            budgetKey: 'daily',
            endTime: '',
          },
        },
        {
          name: '组预算手动出价',
          value: {
            isAutoShoppingAd: false,
            isCbo: false,
            dailyBudget: null,
            lifetimeBudget: null,
            bidStrategy: 'LOWEST_COST_WITH_BID_CAP',
            bidAmount: null,
            budgetKey: 'daily',
            endTime: '',
          },
        },
        {
          name: '组预算目标费用',
          value: {
            isAutoShoppingAd: false,
            isCbo: false,
            dailyBudget: null,
            lifetimeBudget: null,
            bidStrategy: 'COST_CAP',
            bidAmount: null,
            budgetKey: 'daily',
            endTime: '',
          },
        },
        {
          name: '智能广告',
          value: {
            isAutoShoppingAd: true,
            isCbo: false,
            dailyBudget: null,
            lifetimeBudget: null,
            bidStrategy: 'LOWEST_COST_WITHOUT_CAP',
            bidAmount: null,
            budgetKey: 'daily',
            endTime: '',
          },
        },
      ],
      bidList: [],
      adType: [
        { label: '智能广告', value: true },
        { label: '非智能广告', value: false },
      ],
      budgetType: [
        { label: '系列预算', value: true },
        { label: '组预算', value: false },
      ],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      // bidType: [{ label: "自动竞价", value: 'LOWEST_COST_WITHOUT_CAP' },{ label: "目标费用", value: 'COST_CAP' }, { label: "手动出价", value: 'LOWEST_COST_WITH_BID_CAP' }],
    };
  },
  components: {
    adsTitle,
  },
  computed: {},
  watch: {
    campaign: {
      handler() {
        console.log(this.campaign?.adBudgetInfos, 'eeeeeeeeeeeeeeeeeee');
        this.bidList =
          this.campaign && this.campaign?.adBudgetInfos.length > 0
            ? this.campaign.adBudgetInfos.map((v) => {
                v.budgetKey = v.lifetimeBudget ? 'lifetime' : 'daily';
                return JSON.parse(JSON.stringify(v));
              })
            : [];
        if (!this.bidList || !this.bidList.length) {
          this.initList();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    initList() {
      this.bidList.push({ ...this.typeList[0].value, startTime: moment().format('YYYY-MM-DD HH:mm:ss') });
    },
    // 开始时间验证
    validateStartTime(rule, value, callback) {
      callback();
      //   if (new Date(value).getTime() > moment().startOf('day').getTime()) {
      //     callback('开始时间不能小于今天');
      //   } else {
      //     callback();
      //   }
    },
    // 结束时间验证
    validateEndTime(startTime, rule, value, callback) {
      if ((startTime && new Date(startTime).getTime()) >= new Date(value).getTime() - 8.64e7) {
        callback('结束时间必须大于开始时间至少24小时');
      } else {
        callback();
      }
    },
    // 添加
    addBid(val) {
      this.bidList.push({ ...val });
      this.emitMsg();
    },
    // 删除
    removeBid(index) {
      if (this.bidList.length <= 1) {
        this.$message({
          type: 'warning',
          message: '请至少保留一项',
        });
        return false;
      }
      this.bidList.splice(index, 1);
      this.emitMsg();
    },
    // 选择是否是购物广告
    selectAdType(v, index) {
      if (v) {
        this.bidList[index].isCbo = false;
        this.bidList[index].lifetimeBudget = null;
        this.bidList[index].bidStrategy = 'LOWEST_COST_WITHOUT_CAP';
        this.bidList[index].bidAmount = null;
      }
      this.emitMsg();
    },
    // 选择预算类型
    selectBudgetType(v, index) {
      this.bidList[index]['dailyBudget'] = null;
      this.bidList[index]['lifetimeBudget'] = null;
      if (v == 'lifetime') {
        this.bidList[index]['endTime'] = moment().add(7, 'days').format('YYYY-MM-DD HH:mm:ss');
      } else {
        this.bidList[index]['endTime'] = null;
      }
      // this.bidList[index] = JSON.parse(JSON.stringify(this.bidList[index]))
      //   this.emitMsg();
    },
    // 选择出价类型
    selectBidType(v, index) {
      if (v == 'LOWEST_COST_WITHOUT_CAP') {
      }
      this.bidList[index]['bidAmount'] = null;
      this.emitMsg();
    },
    // 选择开始时间
    setStartTime(v, index) {
      let time = moment(v).format('YYYY-MM-DD HH:mm:ss');
      this.bidList[index]['startTime'] = time;
      this.emitMsg();
    },
    // 选择结束时间
    setEndTime(v, index) {
      let time = moment(v).format('YYYY-MM-DD HH:mm:ss');
      this.bidList[index]['endTime'] = time;
      this.emitMsg();
    },
    bidType(v) {
      let list = this.initData.bidTypeMap?.map((v) => {
        return {
          label: v.desc,
          value: v.code,
        };
      });
      return v ? list.filter((item) => item.value != 'LOWEST_COST_WITH_BID_CAP') : list;
    },
    vaildForm() {
      return this.$refs['form'].map((from) => {
        return new Promise((resolve, reject) => {
          from.validate((v) => {
            resolve(v);
          });
        });
      });
    },
    emitMsg() {
      let list = this.bidList.map((v) => {
        v.stateTime = null;
        return v;
      });
      this.$emit('emitMsg', list, this.index);
    },
  },
  mounted() {
    // this.initList()
  },
};
</script>
<style lang="scss" scoped>
.el-form {
  .el-input-group {
    width: 100%;
  }
}
.bidAmount .el-row {
  position: static;
  .el-form .el-input-group {
    width: 100%;
  }
}
</style>
