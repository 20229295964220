<template>
  <div>
    <el-form-item
      label="投放链接"
      prop="promoteUrl"
    >
      <el-input
        v-model="ruleForm.promoteUrl"
        @blur="onInputWebLink(ruleForm.promoteUrl)"
        @keyup.enter.native="onInputWebLink(ruleForm.promoteUrl)"
      ></el-input>
      <p
        :class="['tipsColor', checkLinkStatus && checkLinkStatus.level ? checkLinkStatus.level : '']"
        v-loading="checkLinkStatus.checkLoading"
      >
        {{ checkLinkStatus ? checkLinkStatus.message : '' }}
      </p>
    </el-form-item>
    <div>
      <add-sale
        @saleInfo="saleInfo"
        :msg="ruleForm"
        ref="addSale"
        :size="100"
      ></add-sale>
    </div>
  </div>
</template>
<script>
import { websiteUrlCheck, promoteableSale } from '@/api/autoUploadGoods/common.js';
import addSale from '@/views/adManagement/createAd/components/addSale.vue';
export default {
  props: {
    accountMsg: {
      type: Object,
      default: () => {},
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      ruleForm: {
        promoteUrl: '',
      },
      checkLinkStatus: {
        message: '请输入完整的网址，需包含https或者http。例如：https://www.giikin.com/页面路径…',
        level: 'error',
        checkLoading: false,
      },
      getSaleFlag: false,
    };
  },
  components: {
    addSale,
  },
  computed: {
    accountId() {
      return this.accountMsg.adAccountId;
    },
    thirdUserId() {
      return this.accountMsg.thirdId;
    },
  },
  watch: {
    thirdUserId() {
      this.ruleForm = { promoteUrl: '' };
    },
    id() {},
  },
  methods: {
    setEditPromoteUrl() {
      if (this.id) {
        this.$nextTick(() => {
          this.ruleForm.promoteUrl = this.accountMsg.promoteUrl;
          this.onInputWebLink(this.accountMsg.promoteUrl);
        });
      }
    },
    onInputWebLink(v) {
      if (this.getSaleFlag || this.checkLinkStatus.checkLoading) return;
      this.emitMsg();
      if (!v) {
        this.checkLinkStatus.level = 'error';
        this.checkLinkStatus.message = '请输入完整的网址，需包含https或者http。例如：https://www.giikin.com/页面路径…';
        return false;
      }
      if (!this.accountId) {
        this.$message({
          type: 'warning',
          message: '请先选择广告账户',
        });
        return false;
      }
      this.checkLinkStatus.checkLoading = true;
      websiteUrlCheck({ accountId: this.accountId, siteUrl: v }, { thirdLoginUserId: this.thirdUserId })
        .then((res) => {
          // console.log(res);
          this.checkLinkStatus.checkLoading = false;
          this.checkLinkStatus = { ...this.checkLinkStatus, ...res.data };
          if (res.data.level == 'ok') {
            this.checkLinkStatus.message = '网址可用';
            // this.$set(this.ruleForm,'conversion_domain',el)
            this.getInfoByLink(v);
          } else {
            this.checkLinkStatus.checkLoading = false;
            this.ruleForm.sale = null;
            this.ruleForm.site = null;
            this.emitMsg();
          }
        })
        .catch((err) => {
          this.checkLinkStatus.checkLoading = false;
        });
    },
    // 通过链接获取绑定的像素，商品和站点
    getInfoByLink(url) {
      this.getSaleFlag = true;
      promoteableSale({ url: url }, { thirdLoginUserId: this.thirdUserId })
        .then((res) => {
          this.getSaleFlag = false;
          if (res.code == 0) {
            const data = res.data;
            // 标记像素
            // this.pixelLists = this.pixelLists.map((v) => {
            //     if (data.adPixels && data.adPixels.length) {
            //         v.tips = data.adPixels.includes(v.pixel_id) ? '' : '未绑定站点';
            //     }
            //     return v;
            // });
            // if (data.site) {
            this.$refs['addSale'].remoteMethod(data.site?.id);
            this.$set(this.ruleForm, 'site', data.site);
            // }
            // if (data.sale) {
            this.$set(this.ruleForm, 'sale', data.sale);
            // }
            // if (data.countryAutofill) {
            // this.$set(this.ruleForm, 'countryAutofill', data.countryAutofill);
            // }
            this.$forceUpdate();
          }
        })
        .catch(() => {
          this.getSaleFlag = false;
        });
    },
    // 获取商品信息
    saleInfo(v) {
      console.log(v);
      this.ruleForm = { ...this.ruleForm, saleId: v.sale?.saleId, siteId: v.site?.id, sale: v.sale, site: v.site };
      this.emitMsg();
    },
    emitMsg() {
      this.$emit('setForm', this.ruleForm);
    },
  },
};
</script>
<style lang="scss">
.tipsColor {
  font-size: 12px;
  color: #666;

  &.error {
    color: #f56c6c;
  }

  &.warn {
    color: #e6a23c;
  }

  &.ok {
    color: #67c23a;
  }
}
</style>
