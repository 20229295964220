<template>
    <div>
        <el-dialog
            title="选择兴趣词"
            :visible="addFlexShow"
            width="1200px"
            @close="cancle"
            append-to-body
            @opened="initData">
            <div>
                <flexible-spec v-model="flexibleSpec" Thirdloginuserid="121433760925755" accountId="1241950619948287"></flexible-spec>
            </div>
            <div slot="footer">
                <el-button @click="cancle">取 消</el-button>
                <el-button type="primary" @click="confirmFlex">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import FlexibleSpec from '@/views/adManagement/createAd/components/targeting/FlexibleSpec.vue';
    export default {
        props:{
            addFlexShow:{
                type:Boolean,
                default:false,
            },
            flexData:{
                type:Object,
                default:()=>{}
            }
        },
        components:{
            FlexibleSpec
        },
        data(){
            return {
                flexibleSpec:{}
            }
        },
        methods:{
            cancle(){
                this.$emit('update:addFlexShow',false)
            },
            initData(){
                this.flexibleSpec = JSON.parse(JSON.stringify(this.flexData))
            },
            confirmFlex(){
                this.$emit('setFlexSpec',this.flexibleSpec)
                this.cancle()
            }
        }
    }
</script>
<style lang="scss" scoped></style>